import React from 'react';
import { useSelector } from 'react-redux';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import LSpan from 'Components/common/localizedTags/localizedSpan';

import { getLocalisedPrice } from 'Utils/currencyUtils';
import { getCurrentLanguageCode } from 'Utils/stateUtils';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import fonts from 'Static/typography/fonts';
import TYPE_LABELS from 'Static/typography/labels';

const BasicPriceWrapper = styled.div`
	.from-text {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};
	}
`;

const FromText = styled.div`
	.from-text {
		color: ${colors.GREY['66']};
		display: flex;
		gap: 0.25rem;

		> div {
			margin: 0;
		}
	}
`;

const PriceDiscountWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	&.no-discount > div {
		margin-bottom: -0.125rem;
	}
`;

const ListingPrice = styled.div`
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 0.75rem;
`;

const FinalPriceText = styled.div`
	font-family: ${fonts.HALYARD.DISPLAY};
	font-size: 1.5rem;
	font-weight: 500;
	color: ${colors.GREY['44']};

	.banner-final-price-text {
		font-size: 1.25rem;
	}
`;

const OriginalPriceText = styled.div`
	margin-left: 0.3125rem;
	margin-top: 0.0625rem;
	color: ${colors.GREY['66']};
	font-size: 0.875rem;
	text-decoration: line-through;

	.banner-original-price-text {
		font-size: 0.75rem;
	}
`;

const ListingCTA = styled.div`
	background-color: ${colors.GREEN_3};
	overflow: auto;
	color: ${colors.WHITE};
	display: flex;
	align-self: center;
	text-align: left;
	border-radius: 0.25rem;
	padding: 0.125rem 0.375rem 0.1875rem 0.375rem;
	width: fit-content;

	.discount-percentage {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL_HEAVY)};
		color: ${colors.WHITE};
	}
`;

const TourBasicPriceWrapper = ({ product }: any) => {
	const { currency, listingPrice } = product;
	const { finalPrice, originalPrice, bestDiscount, otherPricesExist } =
		listingPrice;
	const isDiscounted = originalPrice > finalPrice;
	const finalPriceClass = isDiscounted ? 'discounted' : 'no-discount';

	const language = useSelector(state => getCurrentLanguageCode(state));

	return (
		<BasicPriceWrapper className={finalPriceClass}>
			{(otherPricesExist || isDiscounted) && (
				<FromText>
					<LSpan className='from-text'>
						{strings.FROM}
						{isDiscounted && (
							<OriginalPriceText>
								{getLocalisedPrice(
									originalPrice,
									currency,
									language,
								)}
							</OriginalPriceText>
						)}
					</LSpan>
				</FromText>
			)}
			<PriceDiscountWrapper className={finalPriceClass}>
				<ListingPrice>
					<FinalPriceText className={finalPriceClass}>
						{getLocalisedPrice(finalPrice, currency, language)}
					</FinalPriceText>
				</ListingPrice>
				{bestDiscount >= 3 && (
					<ListingCTA>
						<LSpan className='discount-percentage'>
							{strings.formatString(
								strings.BBC_SAVE_DISCOUNT,
								bestDiscount,
							)}
						</LSpan>
					</ListingCTA>
				)}
			</PriceDiscountWrapper>
		</BasicPriceWrapper>
	);
};

export default TourBasicPriceWrapper;
